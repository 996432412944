import { Image, Polygon, Svg } from "@react-pdf/renderer";
import { azureSASTokenToUrl } from "../../formatted/FormattedAzureSasToken";
import { formattedColorMaterial } from "../../formatted/FormattedText";

const adjustDimensions = ({ x_box, y_box, w_box, h_box, imageDimensions }) => {
  const adjustedXBox = Math.max(0, x_box);
  const adjustedYBox = Math.max(0, y_box);
  const adjustedWidth = Math.min(
    w_box + Math.min(0, x_box),
    imageDimensions.width - adjustedXBox,
  );
  const adjustedHeight = Math.min(
    h_box + Math.min(0, y_box),
    imageDimensions.height - adjustedYBox,
  );

  return { adjustedXBox, adjustedYBox, adjustedWidth, adjustedHeight };
};

export const ImageComponent = ({ data, drawMaterial, height }) => {
  const { adjustedXBox, adjustedYBox, adjustedWidth, adjustedHeight } =
    adjustDimensions(data);
  return (
    <Svg
      viewBox={`${adjustedXBox} ${adjustedYBox} ${adjustedWidth} ${adjustedHeight}`}
      style={{
        marginTop: "5px",
        height: height ? height : "800px",
        width: "auto",
        backgroundColor: "#FFF",
      }}
    >
      <Image
        x={0}
        y={0}
        width={data.imageDimensions.width}
        height={data.imageDimensions.height}
        href={azureSASTokenToUrl(data.image_url)}
      />
      {data.wasteObjectsList?.length > 0 &&
        data.wasteObjectsList.map(
          (object) =>
            drawMaterial &&
            object.cls && (
              <Polygon
                points={object.points_konva.join(",")}
                fill={formattedColorMaterial(object.cls)}
                fillOpacity={0.25}
                stroke={formattedColorMaterial(object.cls)}
                strokeOpacity={1}
                strokeWidth={1}
              />
            ),
        )}
    </Svg>
  );
};
