import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaGripLines } from "react-icons/fa";
import { FaCog } from "react-icons/fa";
import { updateDashboardConfiguration } from "../../services/users";
import { useTranslation } from "react-i18next";

const DropdownSortable = ({
  user,
  saveUser,
  onChange,
  displayColumns,
  setDisplayColumns,
  defaultDashboardConfiguration,
  activatedSortingErrorOption,
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedColumns = Array.from(displayColumns);
    const [movedItem] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, movedItem);

    setDisplayColumns(reorderedColumns);
    onChange && onChange(reorderedColumns);
  };

  const toggleSelection = (id) => {
    const updatedColumns = displayColumns.map((col) =>
      col.id === id ? { ...col, selected: !col.selected } : col,
    );
    setDisplayColumns(updatedColumns);
    onChange && onChange(updatedColumns);
  };

  const registerDashboardConfiguration = async () => {
    const updatedUser = {
      ...user,
      dashboardConfiguration: displayColumns,
    };
    saveUser(updatedUser);
    await updateDashboardConfiguration(
      { dashboardConfiguration: displayColumns },
      user._id,
    );
  };

  return (
    <div className="dropdown-sortable">
      {/* Toggle dropdown */}

      <button
        className="config-button open-config-button"
        onClick={() => setIsOpen(true)}
      >
        <FaCog className="config-icon" />
        <span>{t("Configure the dashboard")}</span>
      </button>

      {/* Dropdown content */}
      {isOpen && (
        <div className="dropdown-content">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="columns">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="sortable-list"
                >
                  {displayColumns.map((col, index) => (
                    <Draggable key={col.id} draggableId={col.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="sortable-item"
                        >
                          {/* Drag handle icon */}
                          <FaGripLines className="drag-icon" />
                          {/* Label and checkbox */}
                          <label className="sortable-label">
                            <span className="sortable-name">
                              {t(col.label)}
                            </span>
                            <input
                              type="checkbox"
                              checked={col.selected}
                              onChange={() => toggleSelection(col.id)}
                              className="sortable-checkbox"
                            />
                          </label>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* Ajouter le bouton Valider */}
          <button
            className="btn-validate"
            onClick={() => {
              setIsOpen(false); // Close without saving changes
              setDisplayColumns(
                (user?.dashboardConfiguration.length > 0
                  ? user.dashboardConfiguration
                  : defaultDashboardConfiguration
                ).filter(
                  (config) =>
                    activatedSortingErrorOption || config.id !== "sortingError",
                ),
              ); // Custom function to handle cancel logic
            }}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn-validate"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setIsOpen(false); // Close after saving changes
              registerDashboardConfiguration(); // Custom function to handle validate logic
            }}
          >
            {t("Validate")}
          </button>
        </div>
      )}
    </div>
  );
};

export default DropdownSortable;
