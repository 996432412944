import React, { useState } from "react";
import DeleteModal from "../../components/modals/DeleteModal";
import { handleOpenModalClick } from "../../components/modals/EditableTable";
import { useTranslation } from "react-i18next";

const WasteRow = ({
  data,
  tableData,
  setTableData,
  setIsDeleted,
  formData,
  setFormData,
  setModalEdit,
  unitList,
}) => {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [helpHazardous, setHelpHazardous] = useState(false);

  // Get parentcategory in data.authorizedMaterial
  const authorizedMaterialParentCategory = [
    ...new Set(
      data.authorizedMaterial.map((material) => material.frenchParentCategory),
    ),
  ].filter((material) => material !== undefined);

  // Get parentcategory in materialList if not in data.authorizedMaterial
  const materialList =
    JSON.parse(localStorage.getItem("materialsDescription")) || [];
  const materialListSortingError = materialList.filter(
    (material) =>
      !authorizedMaterialParentCategory.includes(material.frenchParentCategory),
  );
  const parentCategorySortingError = [
    ...new Set(
      materialListSortingError.map((material) => material.frenchParentCategory),
    ),
  ].filter((material) => material !== undefined);

  return (
    <div>
      <section className="relative flex w-full text-sm justify-between bg-white rounded px-2 py-3 my-1 cursor-pointer">
        <div className="flex w-1/6">
          <span className="my-auto">{data.label}</span>
          <div
            className={`${data.hazardous ? "relative flex justify-center mx-2 w-6" : "hidden"}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 5790 5790"
              className="w-6"
              onMouseOver={() => setHelpHazardous(true)}
              onMouseOut={() => setHelpHazardous(false)}
            >
              <path
                d="m253 2897 2640 2640 2641-2640c-880-880-1760-1762-2640-2640L253 2897"
                fill="#fff"
              />
              <path
                d="m60 2897 2833 2833 2834-2834L2894 63 60 2897zm5218 0L2893 5280 510 2896 2894 512l2384 2384"
                fill="red"
              />
              <path d="M2892 1428h-20a475 475 0 0 0-301 121 229 229 0 0 0-64 113c-3 11-3 14-3 35s0 25 3 37c2 14 195 1314 197 1336a213 213 0 0 0 239 182 213 213 0 0 0 180-182c3-27 195-1324 198-1337 2-12 2-16 2-35 0-23 0-31-5-47a234 234 0 0 0-106-138 496 496 0 0 0-291-85h-30zm20 2011h-12a302 302 0 0 0-243 141 338 338 0 0 0-55 232 343 343 0 0 0 114 218 310 310 0 0 0 199 74 316 316 0 0 0 258-141 341 341 0 0 0 54-234 390 390 0 0 0-32-110 317 317 0 0 0-281-180" />
            </svg>
            {helpHazardous && (
              <span className="bg-yellow absolute text-white px-2 rounded -right-36 -top-5">
                {t("Hazardous")}
              </span>
            )}
          </div>
        </div>

        <div className="w-1/6">
          <span>
            {data.wasteCodeFull}
            {data.hazardous ? <sup>*</sup> : null}
          </span>
        </div>

        <div className="w-1/6 mr-4">
          <span>{data.unCodeFull ? data.unCodeFull : "-"}</span>
        </div>

        <div className="w-1/6 mr-4">
          <span>{data.density ? data.density : "-"}</span>
        </div>

        <div className="flex w-1/6 mr-4 pr-10 justify-between">
          <span>{data.treatmentCostFull ? data.treatmentCostFull : "-"}</span>
          <span className="ml-4">
            /
            {unitList.map((unit) =>
              unit.value === data.treatmentCostUnit ? unit.label : null,
            )}
          </span>
        </div>
        <div className="flex w-1/6 mr-8 overflow-hidden whitespace-nowrap">
          {parentCategorySortingError.length > 0 ? (
            parentCategorySortingError.map((material, index) => (
              <div key={`cat-${material._id}`} className=" w-auto mr-1">
                {material}{" "}
              </div>
            ))
          ) : (
            <div className=" w-auto mr-1">-</div>
          )}
        </div>

        <article className="flex justify-end absolute h-full right-0 top-0 w-24 ">
          <div
            className={`action-choice ${isOpen ? "flex flex-col w-60" : ""}`}
          >
            <button
              className="action"
              onClick={() => {
                handleOpenModalClick(data, formData, setFormData, setModalEdit);
                setIsOpen(!isOpen);
              }}
            >
              {t("Edit")}
            </button>
            <button
              className="action"
              onClick={() => {
                setDeleteModal(true);
                setIsOpen(!isOpen);
              }}
            >
              {t("Delete")}
            </button>
          </div>
          <i
            className={` button-action ${!isOpen ? "fas fa-ellipsis-v" : "fas fa-times"}`}
            onClick={() => setIsOpen(!isOpen)}
          ></i>
        </article>

        {deleteModal && (
          <DeleteModal
            tableData={tableData}
            setTableData={setTableData}
            modal={deleteModal}
            setModal={setDeleteModal}
            id={data._id}
            message1={
              t("Do you really want to delete the material") +
              " : \n" +
              data.label +
              " ?"
            }
            message2={data.label}
            messageValidation={t("The material has been successfully deleted")}
            setIsDeleted={setIsDeleted}
            path={"wastecategory/delete/"}
          />
        )}
      </section>
    </div>
  );
};

export default WasteRow;
