import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Card } from "reactstrap";
import Loading from "../../components/utils/Loading";
import Pagination from "../../components/utils/Pagination";
import UserAccessCreate from "./UserAccessCreate";
import UserAccessEdit from "./UserAccessEdit";
import UserAccessFilter from "./UserAccessFilter";
import UserAccessRow from "./UserAccessRow";
import UserHeader from "./UserHeader";
import { ToastContainer } from "react-toastify";
import { getLicensesForContainers } from "../../shared/utils/utils";
import {
  getContainersList,
  getSuperContainerList,
} from "../../services/container";
import { useTranslation } from "react-i18next";

const UserAccess = ({ corporation, viewMode }) => {
  const { t } = useTranslation();
  const API = process.env.REACT_APP_API_BACKEND;
  const token = Cookies.get("userToken");

  const defaultData = {
    firstName: "",
    lastName: "",
    phone: "",
    oldEmail: "",
    email: "",
    password: "",
    passwordConfirm: "",
    active: "",
    role: "client",
    recieveCharacterizationMail: false,
    recieveCharacterizationPush: false,
    recieveCharacterizationSms: false,
    receiveAnomalyMail: false,
    receiveAnomalyPush: false,
    receiveAnomalySms: false,
    receiveFillingMail: false,
    receiveFillingPush: false,
    receiveFillingSms: false,
    receiveRotationMail: false,
    receiveRotationPush: false,
    receiveRotationSms: false,
    token: "",
    authorizedAddresses: [],
  };

  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [totalEntry, setTotalEntry] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [resultPage, setResultPage] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [keywordFilter, setKeywordFilter] = useState("");
  const [modalCreate, setModalCreate] = useState(false);
  const [formData, setFormData] = useState(defaultData);
  const [addFormData, setAddFormData] = useState(defaultData);
  const [sortByField, setSortByField] = useState("firstName");
  const [orderSortByField, setOrderSortByField] = useState("1");
  const [listRoleFilter, setListRoleFilter] = useState({});
  const [roleKeys, setRoleKeys] = useState([""]);
  const [modalEdit, setModalEdit] = useState(false);
  const [addInfo, setAddInfo] = useState(false);
  const [license, setLicense] = useState({});

  /**
   * A ... :
   * @param {dict} pageNumber
   */

  const removeDeletedItem = (id) => {
    const newData = (tableData || []).filter((data) => data.id !== id);
    setTableData(newData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${API}users?page=${currentPage}&limit=${recordPerPage}`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
          params: {
            keywordFilter: keywordFilter,
            sortByField: sortByField,
            orderSortByField: orderSortByField,
            listRoleFilter: listRoleFilter,
          },
        },
      );
      setTotalEntry(response.data.count);
      setTableData(response.data.data);
      setIsLoading(false);
      setIsDeleted(false);
      setRoleKeys(response.data.roleKeys);
    };
    fetchData();
  }, [
    token,
    API,
    totalEntry,
    resultPage,
    currentPage,
    recordPerPage,
    isDeleted,
    keywordFilter,
    sortByField,
    orderSortByField,
    listRoleFilter,
  ]);

  const fetchLicense = async () => {
    if (!tableData) return;

    let monitoringCount = 0;
    let qualityCount = 0;

    const fetchSuperContainers = async () => {
      try {
        const response = await getSuperContainerList();
        return response?.data?.superContainers || [];
      } catch (error) {
        console.error("Error fetching super containers:", error);
        return [];
      }
    };

    const processContainerCounts = (container, superContainers) => {
      const isSuperContainer = superContainers.some((superContainer) =>
        superContainer.containerObjectIdList.includes(container._id),
      );

      if (!isSuperContainer) {
        if (
          container.activatedActivityOption &&
          container.activatedFillingLevelOption &&
          container.activatedSortingErrorOption
        ) {
          monitoringCount++;
        } else {
          qualityCount++;
        }
      }
    };

    const superContainers = await fetchSuperContainers();

    await getContainersList({
      page: null,
      limit: null,
      fieldSort: "filling_level",
      orderSort: "-1",
      typeFillingFilter: "all",
    })
      .then((responseData) => {
        if (responseData.status === 200) {
          responseData.data.data.forEach((container) =>
            processContainerCounts(container, superContainers),
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching containers:", error);
      });

    const licenseMonitoring = getLicensesForContainers(
      monitoringCount,
      "monitoring",
    );

    if (qualityCount === 0 && superContainers) {
      qualityCount += superContainers.length;
    }

    const licenseQuality = getLicensesForContainers(qualityCount, "quality");

    const filteredUserCount = tableData.filter(
      (user) =>
        !user.email.endsWith("@akanthas.com") &&
        !user.email.endsWith("@demo.com"),
    ).length;
    setLicense({
      filteredUserCount: filteredUserCount,
      monitoring: licenseMonitoring,
      quality: licenseQuality,
      maxUserCount: licenseMonitoring.userCount + licenseQuality.userCount,
      userCount: filteredUserCount,
      userLimitReached:
        filteredUserCount >=
        licenseMonitoring.userCount + licenseQuality.userCount,
    });
  };

  useEffect(
    () => {
      fetchLicense();
    },
    // eslint-disable-next-line
    [token, API, tableData],
  );

  if (token) {
    return isLoading ? (
      <Loading color="#F5F5F5" />
    ) : (
      <div className="base">
        <h1>{t("My Accesses")}</h1>
        <UserAccessFilter
          keywordFilter={keywordFilter}
          setKeywordFilter={setKeywordFilter}
          roleKeys={roleKeys}
          setListRoleFilter={setListRoleFilter}
        />
        <div className="flex justify-end w-full">
          <span className="mb-3 mr-3 ">
            {t("Number of users for the contract")} : {license.maxUserCount}
          </span>
        </div>

        <section className="top-table">
          <article className="operation-table">
            <div className="flex relative">
              <button
                className="operation-data operation-button my-auto"
                onClick={() => {
                  if (
                    license &&
                    (!license.userLimitReached || viewMode === "admin")
                  ) {
                    setModalCreate(true);
                  }
                }}
                onMouseOver={() => setAddInfo(true)}
                onMouseOut={() => setAddInfo(false)}
              >
                <i
                  className={`fas fa-plus-circle icon-header`}
                  style={{
                    color:
                      (license && !license.userLimitReached) ||
                      viewMode === "admin"
                        ? "var(--akanthas-cyan)"
                        : "#d1d5db",
                  }}
                ></i>
              </button>
              {addInfo && (
                <span
                  className={`absolute bg-yellow text-white px-2 rounded ${license.userLimitReached ? "-right-56 -top-20 w-64" : "-right-36 -top-5"}`}
                >
                  {license && !license.userLimitReached
                    ? t("Add new user")
                    : viewMode === "admin"
                      ? t("userLimitMessage", {
                          maxUserCount: license.maxUserCount,
                          filteredUserCount: license.filteredUserCount,
                        })
                      : t(
                          "You have reached the number of users for the contract",
                        )}
                </span>
              )}
            </div>
            <div className="operation-data">
              <i className="fas fa-sort-amount-up ico-action "></i>

              <select
                className="sort-select"
                name="sortByField"
                onChange={(event) => setSortByField(event.target.value)}
              >
                <option value="lastName">{t("Name")}</option>
                <option value="firstName">{t("First name")}</option>
                <option value="role">{t("Role")}</option>
                <option value="email">{t("Email")}</option>
              </select>

              <select
                className="sort-select"
                name="orderSortByField"
                onChange={(event) => setOrderSortByField(event.target.value)}
              >
                <option value="1">{t("Ascending")}</option>
                <option value="-1">{t("Descending")}</option>
              </select>
            </div>
          </article>

          <Pagination
            totalEntry={totalEntry}
            recordPerPage={recordPerPage}
            setRecordPerPage={setRecordPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            resultPage={resultPage}
            setResultPage={setResultPage}
          />
        </section>

        <Card className="table">
          <div>
            <UserHeader
              classColumnTable={`hidden lg:flex xl:flex 2xl:flex w-full justify-between bg-white rounded h-14 mx-0 my-2`}
            />

            <article className="content-table">
              {tableData &&
                tableData !== undefined &&
                tableData.map((UserAccess, index) => {
                  if (UserAccess) {
                    return (
                      <Fragment key={`user-${UserAccess._id}`}>
                        {modalEdit && formData._id === UserAccess._id ? (
                          <UserAccessEdit
                            tableData={tableData}
                            setTableData={setTableData}
                            formData={formData}
                            editFormData={formData}
                            setEditFormData={setFormData}
                            modalEdit={modalEdit}
                            setModalEdit={setModalEdit}
                            corporation={corporation}
                          />
                        ) : null}
                        {(viewMode === "admin" ||
                          !UserAccess.email.includes("@akanthas.com")) && (
                          <UserAccessRow
                            data={UserAccess}
                            tableData={tableData}
                            setTableData={setTableData}
                            removeDeletedItem={removeDeletedItem}
                            setIsDeleted={setIsDeleted}
                            setFormData={setFormData}
                            setModalEdit={setModalEdit}
                          />
                        )}
                      </Fragment>
                    );
                  }
                  return null;
                })}

              {modalCreate && (
                <UserAccessCreate
                  addFormData={addFormData}
                  setAddFormData={setAddFormData}
                  defaultData={defaultData}
                  tableData={tableData}
                  setTableData={setTableData}
                  totalEntry={totalEntry}
                  API={API}
                  token={token}
                  setTotalEntry={setTotalEntry}
                  modalCreate={modalCreate}
                  setModalCreate={setModalCreate}
                  corporation={corporation}
                />
              )}
            </article>
          </div>
          <ToastContainer autoClose={3000} style={{ marginTop: "60px" }} />
        </Card>
      </div>
    );
  }
};

export default UserAccess;
