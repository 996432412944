import React, { useState } from "react";
import { handleAddFormSubmit } from "../../components/modals/EditableTable";
import { Modal, ModalBody, Form } from "reactstrap";
import { WasteGeneralInformations } from "./WasteFormTab/WasteGeneralInformations";
import { WasteCosts } from "./WasteFormTab/WasteCosts";
import { WasteSortingErrors } from "./WasteFormTab/WasteSortingErrors";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const WasteCreate = ({
  addFormData,
  setAddFormData,
  defaultData,
  setTableData,
  modalCreate,
  setModalCreate,
  tableData,
  totalEntry,
  setTotalEntry,
  unitList,
}) => {
  const { t } = useTranslation();
  const [showGeneralInformations, setShowGeneralInformations] = useState(true);
  const [showCosts, setShowCosts] = useState(false);
  const [showSortingErrors, setShowSortingErrors] = useState(false);
  const [formIsVerified, setFormIsVerified] = useState(true);
  const [errorForm, setErrorForm] = useState("");
  const [sendingForm, setSendingForm] = useState(false);
  const [items, setItems] = useState(
    JSON.parse(localStorage.getItem("materialsDescription"))
      .map((v) => ({ ...v, isChecked: false }))
      .filter((item) => item.isActiveSortingError),
  );

  const toggle = () => setModalCreate(!modalCreate);
  const path = "wastecategory";

  return (
    <Modal isOpen={modalCreate} toggle={toggle}>
      <Form
        onSubmit={async (event) => {
          setSendingForm(true);
          const response = await handleAddFormSubmit(
            event,
            addFormData,
            setAddFormData,
            defaultData,
            tableData,
            setTableData,
            totalEntry,
            setTotalEntry,
            path,
            setErrorForm,
            setSendingForm,
          );
          if (response.status === 200) {
            setModalCreate(false);
            toast.success("La matière a bien été ajoutée", {
              className: "custom-toast",
            });
          }
        }}
      >
        <section className="flex flex-col p-3">
          {/* Header */}
          <section className="flex justify-between max-md:flex-col mb-4">
            <div className="flex">
              <h4 className="font-semibold text-2xl">{t("New category")}</h4>
              {addFormData.label ? (
                <h4 className="ml-1 text-blush font-semibold text-2xl">
                  | {addFormData.label}
                </h4>
              ) : null}
            </div>

            <div className="flex text-base max-md:justify-end">
              <button
                className="px-3 py-2 mr-2 border border-gray-300 rounded-md hover:bg-neutral-100"
                onClick={() => {
                  setModalCreate(false);
                  setAddFormData(defaultData);
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className={`px-3 py-2 rounded text-white ${!sendingForm && formIsVerified ? "bg-cyan" : "bg-neutral-400 hover:bg-neutral-400"}`}
                type="submit"
                disabled={!formIsVerified || sendingForm}
              >
                {t("Save")}
              </button>
            </div>
          </section>

          {/* Tabs */}
          <section className="flex max-md:flex-col text-base text-cyan ">
            <div
              className={`mr-2 px-1 cursor-pointer ${showGeneralInformations ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(true);
                setShowCosts(false);
                setShowSortingErrors(false);
              }}
            >
              {t("General information")}
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showCosts ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowCosts(true);
                setShowSortingErrors(false);
              }}
            >
              {t("Cost")}
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showSortingErrors ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowCosts(false);
                setShowSortingErrors(true);
              }}
            >
              {t("Unauthorized categories")} {`(${t("sorting errors")})`}
            </div>
          </section>

          {/* Error */}
          {errorForm ? (
            <p className="mx-auto my-0 px-2 py-1 bg-yellow text-white rounded">
              {errorForm}
            </p>
          ) : null}
        </section>

        <ModalBody>
          {showGeneralInformations ? (
            <WasteGeneralInformations
              formData={addFormData}
              setFormData={setAddFormData}
              setErrorForm={setErrorForm}
              setFormIsVerified={setFormIsVerified}
            />
          ) : null}

          {showCosts ? (
            <WasteCosts
              formData={addFormData}
              setFormData={setAddFormData}
              unitList={unitList}
            />
          ) : null}

          {showSortingErrors ? (
            <WasteSortingErrors
              formData={addFormData}
              setFormData={setAddFormData}
              items={items}
              setItems={setItems}
            />
          ) : null}
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default WasteCreate;
