import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";
import myPackmatIcon from "../../static/assets/CompactingIcon.jpg";
import {
  formattedDate,
  formattedTime,
} from "../../components/formatted/FormattedDateAndTime";
import {
  formattedLevelColor,
  toFrenchNumber,
} from "../../components/formatted/FormattedText";
import ActivityTimeLineTooltip from "./ActivityTooltip";
import { useDispatch } from "react-redux";
import { toggleCollection } from "../../redux/collectionSlice";
import {
  getHistoryCycleContainer,
  getAzureBlob,
  saveContainerWeight,
  updateOutputSurface,
} from "../../services/container";
import { getCameraId } from "../../shared/utils/utils";
import { CropImage } from "../../components/utils/ImageTreatment";
import { useTranslation } from "react-i18next";
import ContainerModalClient from "../../components/ClientModalInputs";
import { StarRatingIcon } from "../container/ContainerIcons";
import ActivityCollectionFileModal from "./ActivityCollectionFileModal";
import { getContainerClientRegisteredInfo } from "../../services/container.js";
import pickup from "../../static/assets/PickUp.png";
import dropoff from "../../static/assets/DropOff.png";
import ContainerModalComment from "../../components/ClientModalComment.js";
import useWindowDimensions from "../../components/formatted/UseWindowsDimensions.js";
import { FillingCyclePdfDocument } from "../../components/download/ActivityCycle/FillingCyclePdfDocument.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { pdf } from "@react-pdf/renderer";
import reactImageSize from "react-image-size";
import { azureSASTokenToUrl } from "../../components/formatted/FormattedAzureSasToken.js";
import { getCorporationData } from "../../services/corporation.js";
import cloneDeep from "lodash/cloneDeep";

const imageDimensions = async function getDimensions(src) {
  return await reactImageSize(src);
};

const ActivityCollectionRow = ({
  timeLine,
  index,
  setModalHistoryCycle,
  setDataRow,
  loadScreen,
  handleCsvExport,
  wasteCategory,
  superContainers,
  setActiveWasteCategory,
  activeWasteCategory,
  weightToTonnes,
}) => {
  const { t, i18n } = useTranslation();
  const azureUrl = process.env.REACT_APP_AZURE_URL;
  const { token, ...user } = JSON.parse(localStorage.getItem("user"));
  const [itemIndex, setItemIndex] = useState(null);
  const [historyCycle, setHistoryCycle] = useState([]);
  const [numberOfPackmat, setNumberOfPackmat] = useState(0);
  const [numberOfStars, setNumberOfStars] = useState(3);
  const [numberOfAnomaly, setNumberOfAnomaly] = useState(0);
  const [clientRegisteredWeight, setClientRegisteredWeight] = useState(null);
  const [wasteName, setWasteName] = useState(timeLine.wasteName);
  const [dropOff, setDropOff] = useState("");
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState({});
  const [checkStatus, setCheckStatus] = useState("-");
  const [tags, setTags] = useState([]);

  const [wasteCategoryName, setWasteCategoryName] = useState("{}");
  const [displayWeight, setDisplayWeight] = useState(false);
  const [displayClient, setDisplayClient] = useState(false);
  const [displayExport, setDisplayExport] = useState(false);
  const [displayTags, setDisplayTags] = useState(false);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [displayComment, setDisplayComment] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageData, setImageData] = useState(null);
  const [outputClient, setOutputClient] = useState({});
  const [totalVolumeSortingError, setTotalVolumeSortingError] = useState(0);
  const [totalWasteVolume, setTotalWasteVolume] = useState(0);
  const [showAction, setShowAction] = useState(false);
  const [imageWidth, setImageWidth] = useState(180);
  const [imageHeight, setImageHeight] = useState(180);
  const { widthWindow } = useWindowDimensions();
  const [collectionFillingLevel, setCollectionFillingLevel] = useState(0);

  const weightRef = useRef(null);
  const dispatch = useDispatch();

  const controller = new AbortController();

  useEffect(() => {
    if (widthWindow < 1080) {
      setImageWidth(300);
      setImageHeight(300);
    } else {
      setImageWidth(180);
      setImageHeight(180);
    }
  }, [widthWindow]);

  const toggleDisplayWeight = () => {
    setDisplayWeight(!displayWeight);
    setDisplayClient(true);
    setNewClient(false);
    setDisplayStatus(false);
    setDisplayTags(false);
  };

  const handleSelectionChange = (event) => {
    const selectedValue = JSON.parse(event.target.value);
    setClientName(selectedValue?.name);
    setClientCode(selectedValue?.code);
    setClientAddress(selectedValue?.address);
  };

  const fetchData = async (controller) => {
    setLoading(true);

    try {
      const responseHistoryCycle = getHistoryCycleContainer(
        {
          currentCycle: false,
          getLevelLastRotation: false,
          containerCollectionDate: timeLine._time,
        },
        timeLine._measurement,
        controller.signal,
      );

      // Await both requests using axios.all and axios.spread
      const [resHistoryCycle] = await axios.all([responseHistoryCycle]);

      if (resHistoryCycle.status === 200) {
        setHistoryCycle(resHistoryCycle.data.historyCycle);
        setCollectionFillingLevel(
          resHistoryCycle.data.historyCycle[0].filling_level,
        );
        setDropOff(
          resHistoryCycle.data.historyCycle[
            resHistoryCycle.data.historyCycle.length - 1
          ]?._time,
        );
        setImageData(
          cloneDeep(
            resHistoryCycle.data.historyCycle[
              timeLine.showDepotCycle
                ? resHistoryCycle.data.historyCycle.length - 1 // Last entry
                : 0 // First entry
            ],
          ),
        );
        setNumberOfPackmat(resHistoryCycle.data.numberOfPackmat);
        setNumberOfStars(resHistoryCycle.data.numberOfStars);
        setNumberOfAnomaly(resHistoryCycle.data.numberOfAnomaly);
        setTotalVolumeSortingError(
          resHistoryCycle.data.totalVolumeSortingError,
        );
        setTotalWasteVolume(resHistoryCycle.data.totalWasteVolume);

        const historyData = {
          historyCurrent: resHistoryCycle.data.historyCycle,
          numberOfAnomaly: resHistoryCycle.data.numberOfAnomaly,
          numberOfStars: resHistoryCycle.data.numberOfStars,
          anomalyDelivery: resHistoryCycle.data.anomalyDelivery,
          numberOfPackmat: resHistoryCycle.data.numberOfPackmat,
          totalVolumeSortingError: resHistoryCycle.data.totalVolumeSortingError,
          totalWasteVolume: resHistoryCycle.data.totalWasteVolume,
          lastPackmatEfficiency:
            resHistoryCycle?.data?.lastPackmatEfficiency.length > 0
              ? resHistoryCycle.data.lastPackmatEfficiency[0]
              : null,
        };

        dispatch(
          toggleCollection({
            [`${timeLine._measurement}_${timeLine._time}`]: historyData,
          }),
        );
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData(controller);

    return () => {
      controller.abort(); // Cleanup function to cancel the request if dependencies change
    };
    // eslint-disable-next-line
  }, [timeLine._time, timeLine._measurement]);

  const fetchContainerClientRegisteredInfo = async (id, signal) => {
    if (Object.keys(superContainers).length > 0) {
      if (superContainers.containerObjectIdList.includes(id)) {
        id = superContainers._id;
      }
    }
    if (dropOff !== "" && timeLine._time) {
      try {
        const response = await getContainerClientRegisteredInfo(
          {
            start: new Date(new Date(dropOff).getTime() - 1).toISOString(),
            stop: new Date(
              new Date(timeLine._time).getTime() + 1,
            ).toISOString(),
          },
          id,
          signal, // Pass the signal to support cancellation
        );

        if (Object.keys(response?.data).length > 0) {
          setClientName(
            response?.data?.client
              ? JSON.parse(response?.data?.client).name
              : "",
          );

          setClientCode(
            response?.data?.client
              ? JSON.parse(response?.data?.client).code
              : "",
          );

          setFiles(() => {
            const clientData = response?.data?.client
              ? JSON.parse(response.data.client)
              : {};
            return clientData.files || [];
          });

          setCheckStatus(
            response?.data?.client
              ? JSON.parse(response?.data?.client).checkStatus
              : "No status",
          );

          setTags(
            response?.data?.client
              ? JSON.parse(response?.data?.client).tags
              : [],
          );

          setComments(
            response?.data?.comments
              ? JSON.parse(response?.data?.comments)
              : [],
          );

          setWasteCategoryName(
            response?.data?.waste_category
              ? response?.data?.waste_category
              : "",
          );

          setClientRegisteredWeight(response?.data?.weight / 1000);
        }
      } catch (error) {
        console.error("Error fetching container client registered info", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (timeLine._id !== undefined) {
      fetchContainerClientRegisteredInfo(timeLine._id, controller.signal);
    }

    return () => {
      controller.abort(); // Clean up by aborting the request if dependencies change
    };
    // eslint-disable-next-line
  }, [timeLine._id, superContainers, dropOff]);

  const saveOutputSurface = async (waste_category) => {
    if (waste_category !== null) {
      setLoading(true);
      const cameraId = getCameraId(imageData.image_url);
      const wasteCategory = JSON.parse(waste_category);
      const promises = historyCycle.map(async (history) => {
        let newObjectSegmentations = {};
        let anomaliesCount = 0;
        let params = {
          waste_category_label: wasteCategory.wasteCategoryName,
          waste_category_value: wasteCategory.wasteCategoryValue,
          waste_category_code: wasteCategory.wasteCategoryCode,
          waste_category_density: wasteCategory.wasteCategoryDensity,
          waste_category_authorized_materials:
            wasteCategory.wasteCategoryAuthorizedMaterial,
          containerId: timeLine._id,
          time: history._time,
        };
        const outputSurfaceBlob = `camera-ak_${cameraId}/output-surface/${history._time.replace(/:/g, "_").slice(0, -1)}-camera-ak_${cameraId}-${timeLine._id}-surface.json`;
        const blobUrl = azureUrl + outputSurfaceBlob;

        try {
          const azureResponse = await getAzureBlob({ blobUrl: blobUrl });
          let outputSurface = azureResponse.data;
          newObjectSegmentations = outputSurface.object_segmentations.map(
            (item) => ({
              ...item,
              isAuthorizedMaterial:
                wasteCategory.wasteCategoryAuthorizedMaterial.some(
                  (material) => material.value === item.cls,
                ),
            }),
          );

          anomaliesCount = newObjectSegmentations.filter(
            (material) => !material.isAuthorizedMaterial,
          ).length;

          params = {
            ...outputSurface,
            ...params,
            object_segmentations: newObjectSegmentations,
            anomalies: anomaliesCount,
          };
        } catch (error) {
          if (error.response && error.response.status === 404) {
            if (history?.anomaliesAnnotation) {
              newObjectSegmentations = history.anomaliesAnnotation.map(
                (item) => ({
                  ...item,
                  isAuthorizedMaterial:
                    wasteCategory.wasteCategoryAuthorizedMaterial.some(
                      (material) => material.value === item.cls,
                    ),
                }),
              );

              anomaliesCount = newObjectSegmentations.filter(
                (material) => !material.isAuthorizedMaterial,
              ).length;
            }

            params = {
              ...params,
              object_segmentations: newObjectSegmentations,
              anomalies: anomaliesCount,
            };
          } else {
            console.error(error);
          }
        }
        const data = {
          data: params,
          blobName: outputSurfaceBlob,
        };
        await updateOutputSurface(data);
      });
      await Promise.all(promises);
      setLoading(false);
      setDisplayWeight(false);
      setDisplayClient(false);
      setNewClient(false);
      setDisplayStatus(false);
      setDisplayTags(false);
    }
  };

  const handleSubmit = async (waste_category, changeWasteCategory) => {
    setLoading(true);
    let commentsList = comments;

    if (comment.text !== "") {
      const filteredComments = comments.filter(
        (item) => item.time !== comment.time,
      );

      commentsList = comment?.toDelete
        ? filteredComments
        : [...filteredComments, comment];

      setComment({
        time: new Date().toISOString(),
        author: user,
        text: "",
      });
    }

    setComments(commentsList);
    setWasteName(JSON.parse(wasteCategoryName).wasteCategoryName);
    if (waste_category !== null || !changeWasteCategory) {
      let id = timeLine._id;
      if (Object.keys(superContainers).length > 0) {
        id = superContainers._id;
      }
      const cameraId = getCameraId(imageData.image_url);
      const blobName = `${
        "camera-ak_" + cameraId
      }/output-client/${timeLine._time.replace(/:/g, "_").slice(0, -1)}-${
        "camera-ak_" + cameraId
      }-${id}-client.json`;
      const blobUrl = azureUrl + blobName;
      try {
        const azureResponse = await getAzureBlob({ blobUrl: blobUrl });
        let data = azureResponse.data;

        data = {
          ...data,
          ...{
            weight:
              parseFloat(String(clientRegisteredWeight).replace(",", ".")) *
              1000,
            time: timeLine._time,
            modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            client: JSON.stringify({
              name: clientName ? clientName : "",
              code: clientCode ? clientCode : "",
              address: clientAddress ? clientAddress : "",
              checkStatus: checkStatus
                ? typeof checkStatus === "string" &&
                  checkStatus.trim().startsWith("{")
                  ? JSON.parse(checkStatus)
                  : checkStatus
                : "-",
              tags: tags ? tags : [],
              ...(files && files.length > 0 && { files: files }),
            }),
            waste_category: wasteCategoryName,
            blobName: blobName,
            comments: JSON.stringify(commentsList),
          },
        };
        await saveContainerWeight(data);
      } catch (error) {
        if (error.response.status === 404) {
          await saveContainerWeight({
            id: id,
            weight:
              parseFloat(String(clientRegisteredWeight).replace(",", ".")) *
              1000,
            time: timeLine._time,
            modified_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            client: JSON.stringify({
              name: clientName ? clientName : "",
              code: clientCode ? clientCode : "",
              address: clientAddress ? clientAddress : "",
              checkStatus: checkStatus
                ? typeof checkStatus === "string" &&
                  checkStatus.trim().startsWith("{")
                  ? JSON.parse(checkStatus)
                  : checkStatus
                : "-",
              tags: tags ? tags : [],
              ...(files && files.length > 0 && { files: files }),
            }),
            waste_category: wasteCategoryName,
            blobName: blobName,
            comments: JSON.stringify(commentsList),
          });
        } else {
          console.error(error);
        }
      }
      if (changeWasteCategory) {
        await saveOutputSurface(wasteCategoryName);
      }
      await fetchData(controller);
      setDisplayWeight(!displayWeight);
      setDisplayClient(false);
      setNewClient(false);
      setTotalWeight(clientRegisteredWeight);
      fetchContainerClientRegisteredInfo(timeLine._id);
      setLoading(false);
    }
  };

  function getWeightText(clientRegisteredWeight, timeLine, historyCycle) {
    if (clientRegisteredWeight) {
      return `${t("Registered weight")} ${clientRegisteredWeight} ${t("ton")}${clientRegisteredWeight > 1 ? "s" : ""}`;
    }

    if (timeLine.density === 0) {
      return (
        <span
          style={{ marginLeft: 4 }}
          title={t(
            "Set a mass or add a default density for an estimated weight",
          )}
          className="flex flex-row items-center rounded-full hover:bg-gray-300 px-3 cursor-pointer border-solid border-1 border-[#00A7A6] text-[#00A7A6] hover:text-white hover:border-gray-300"
          onClick={() => {
            if (historyCycle && historyCycle.length > 0) {
              toggleDisplayWeight();
            }
            setIsOpen(!isOpen);
          }}
        >
          {t("Add a mass")}
        </span>
      );
    }

    const estimatedWeight = toFrenchNumber(
      weightToTonnes(historyCycle[0].mass, timeLine.weightUnit), // Pass default or calculated value
      1,
    );
    const unit = timeLine.weightUnit === "kg" ? "kg" : t("ton");
    const plural =
      weightToTonnes(historyCycle[0].mass, timeLine.weightUnit) > 1 ? "s" : "";

    return `${t("Estimated weight")} ${estimatedWeight} ${unit}${plural}`;
  }

  const downloadPdf = async (pdfContent, filename) => {
    const pdfBlob = await pdf(pdfContent).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(pdfBlob);
    link.download = filename;
    link.click();
  };

  const generatePdf = async () => {
    const targets = Array.from({ length: 10 }, (_, i) => (i + 1) * 10);

    let corporationName = "";
    await getCorporationData(user).then((responseCorporation) => {
      if (
        responseCorporation.status >= 200 &&
        responseCorporation.status < 400
      ) {
        corporationName = responseCorporation.data.corporation.corporationName;
      }
    });

    const findClosest = (array, target) =>
      array.reduce((prev, curr) =>
        Math.abs(curr.filling_level - target) <
        Math.abs(prev.filling_level - target)
          ? curr
          : prev,
      );

    const filteredData = [];
    const usedValues = new Set();

    targets.forEach((target) => {
      const closest = findClosest(historyCycle, target);
      if (!usedValues.has(closest.filling_level)) {
        filteredData.push(closest);
        usedValues.add(closest.filling_level);
      }
    });

    const finalData = [];
    for (const item of filteredData) {
      const dimensions = await imageDimensions(
        azureSASTokenToUrl(item.image_url),
      );
      finalData.push({ ...item, imageDimensions: dimensions });
    }

    setLoading(true);
    const pdfContent = (
      <FillingCyclePdfDocument
        data={finalData}
        timeLine={timeLine}
        dropOff={dropOff}
        corporationName={corporationName}
        collectionFillingLevel={collectionFillingLevel}
        numberOfAnomaly={numberOfAnomaly}
      />
    );
    const pdfFilename = `${i18n.language === "en" ? "Filling_cycle" : i18n.language === "fr" ? "Cycle_de_remplissage" : "Ciclo_de_llenado"}_${timeLine.wasteName}_${formattedDate(timeLine._time, i18n.language, true)}.pdf`;
    await downloadPdf(pdfContent, pdfFilename);
    setLoading(false);
    toast.success("Votre fichier a bien été téléchargé", {
      className: "custom-toast",
    });
  };

  return (
    <section className="time-line-element" key={index}>
      <div className="flex flex-row w-full my-3 p-2 bg-white rounded-md">
        <div className="2xl:w-1/6 xl:w-1/6 lg:w-1/6 md:w-3/6 sm:w-2/5 mr-2">
          {imageData && (
            <CropImage
              container={timeLine}
              data={imageData}
              imgDivWidth={imageWidth}
              imgDivHeight={imageHeight}
              isTooltip={false}
              isMap={false}
              isCharacterization={false}
              displayLegend={false}
              materialsDropdownToDisplay={[]}
              showCheckbox={false}
            />
          )}
        </div>
        <div className="flex flex-col 2xl:w-5/6 xl:w-5/6 lg:w-5/6 md:w-4/6 sm:w-3/5">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-row gap-2 items-center">
              <span className="text-lg font-bold text-[#0000ff]">
                {wasteCategoryName &&
                wasteCategoryName !== "{}" &&
                wasteCategoryName !== ""
                  ? JSON.parse(wasteCategoryName).wasteCategoryName
                  : wasteName}
              </span>
              <i class={`mr-1 ${checkStatus?.icon}`}></i>
            </div>
            <div className="flex flex-row gap-2">
              <div
                className={`flex flex-row text-sm py-1 px-1 text-white items-center justify-center rounded-full h-7 w-7 ${loading ? "bg-[#D0D0D0]" : "cursor-pointer bg-[#00A7A6] hover:bg-yellow"}`}
              >
                <i
                  class="fas fa-sign-in-alt fa-rotate-90"
                  onClick={() => {
                    if (historyCycle && historyCycle.length > 0) {
                      setDisplayExport(!displayExport);
                    }
                  }}
                ></i>
                {displayExport && (
                  <div
                    className={`w-32 flex flex-end flex-col bg-white rounded absolute mt-28 ml-20 border border-gray-200`}
                  >
                    <span
                      className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                      onClick={() => {
                        setDataRow(timeLine);
                        setActiveWasteCategory(
                          wasteCategoryName !== ""
                            ? JSON.parse(wasteCategoryName).wasteCategoryName
                            : "",
                        );
                        handleCsvExport(
                          historyCycle,
                          timeLine,
                          clientName,
                          clientCode,
                          clientRegisteredWeight,
                        );
                        setDisplayExport(!displayExport);
                        setIsOpen(!isOpen);
                      }}
                    >
                      CSV (.csv)
                    </span>
                    <span
                      className="block px-4 py-2 text-sm text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700 cursor-pointer"
                      onClick={() => {
                        setDisplayExport(!displayExport);
                        generatePdf();
                      }}
                    >
                      PDF (.pdf)
                    </span>
                  </div>
                )}
              </div>
              <div
                className={`flex flex-row text-sm py-1 px-1 text-white items-center justify-center rounded-full h-7 w-7 ${loading ? "bg-[#D0D0D0]" : "cursor-pointer bg-[#00A7A6] hover:bg-yellow"}`}
                onClick={() => {
                  if (!loading) {
                    setShowAction(!showAction);
                  }
                }}
              >
                <i class="fas fa-edit"></i>
              </div>
              <div
                className={`flex flex-row text-sm py-1 px-1 text-white items-center justify-center rounded-full h-7 w-7 ${loading ? "bg-[#D0D0D0]" : "cursor-pointer bg-[#00A7A6] hover:bg-yellow"}`}
                onClick={() => {
                  if (!loading) {
                    setActiveWasteCategory(
                      wasteCategoryName !== ""
                        ? JSON.parse(wasteCategoryName).wasteCategoryName
                        : "",
                    );
                    setModalHistoryCycle(true);
                    setDataRow(timeLine);
                  }
                }}
              >
                <i className="fas fa-eye"></i>
              </div>
            </div>
          </div>
          <div className="w-full flex border-b border-gray-300 pt-3 pb-2">
            <div className="w-full flex flex-wrap gap-2 text-sm text-gray-500">
              {timeLine?.positioning && (
                <span className="rounded-full py-1 px-3 cursor-pointer border border-gray-500">
                  {timeLine.positioning}
                </span>
              )}
              <span className="rounded-full py-1 px-3 cursor-pointer border border-gray-500">
                {timeLine.addressName}
              </span>
              {tags && tags.length > 0
                ? tags.map(
                    (item) =>
                      item !== "tag1" && (
                        <span
                          key={item.id}
                          className="rounded-full py-1 px-3 cursor-pointer border border-gray-500"
                        >
                          {i18n.language === "en" ? item.labelEn : item.labelFr}
                        </span>
                      ),
                  )
                : ""}
            </div>
          </div>
          <div className="w-full grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-4 py-2">
            <div className="flex flex-col text-sm text-gray-500">
              {historyCycle && (
                <div className="flex flex-row">
                  <img src={dropoff} alt="pickup" style={{ height: 15 }} />
                  <span className="ml-2 mr-3">{t("Drop-off")} :</span>
                  {formattedDate(
                    historyCycle[historyCycle.length - 1]?._time,
                    i18n.language,
                    true,
                  )}
                  ,{" "}
                  {formattedTime(historyCycle[historyCycle.length - 1]?._time)}
                </div>
              )}
              {(timeLine.showDepotCycle === undefined ||
                !timeLine.showDepotCycle) && (
                <div className="flex flex-row mt-2.5">
                  <img src={pickup} alt="pickup" style={{ height: 15 }} />
                  <span className="ml-2 mr-3">{t("Pick-up")} :</span>
                  {formattedDate(timeLine._time, i18n.language)},{" "}
                  {formattedTime(timeLine._time)}
                </div>
              )}
            </div>
            <div className="flex flex-col text-sm text-gray-500">
              {timeLine.activatedFillingLevelOption &&
                (timeLine.showDepotCycle === undefined ||
                  !timeLine.showDepotCycle) && (
                  <div className="flex flex-row">
                    <div
                      className="progress-bar"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div
                        className={`progress-filling ${formattedLevelColor(
                          Number(collectionFillingLevel),
                          timeLine.limitFillingLevel,
                          "reverse",
                        )}`}
                        style={{ height: `${collectionFillingLevel}%` }}
                      ></div>
                    </div>
                    <span>
                      {t("Level at collection")} : {collectionFillingLevel}%
                    </span>
                  </div>
                )}
              <div className="flex flex-row mt-2.5 ml-1">
                <img
                  src={myPackmatIcon}
                  alt="Packmat Icon"
                  style={{ height: 15 }}
                />
                <span style={{ marginLeft: 10 }}>
                  {t("Packmat")} : {numberOfPackmat}
                </span>
              </div>
              <div className="flex flex-row mt-2.5 ml-1">
                <i class="fas fa-weight-hanging"></i>
                <span className="text" style={{ marginLeft: 5 }}></span>
                {historyCycle && historyCycle.length > 0 && !loading && (
                  <>
                    <span style={{ marginLeft: 4 }}>
                      {getWeightText(
                        clientRegisteredWeight,
                        timeLine,
                        historyCycle,
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col text-sm text-gray-500">
              {timeLine.activatedSortingErrorOption && (
                <div className="flex flex-col">
                  {loading || (historyCycle && historyCycle.length < 1) ? (
                    <StarRatingIcon
                      numberOfStars={numberOfStars}
                      alignTextAboveStars={true}
                      color={"var(--gainsboro)"}
                      textSize={12}
                      iconSize={18}
                    />
                  ) : (
                    <div className="flex flex-row">
                      <StarRatingIcon
                        numberOfStars={numberOfStars}
                        alignTextAboveStars={true}
                        color={"grey"}
                        textSize={12}
                        iconSize={18}
                      />
                      <span className="ml-1">
                        {totalVolumeSortingError > 0
                          ? (totalVolumeSortingError * 100) / totalWasteVolume <
                            1
                            ? "<1"
                            : Math.round(
                                (totalVolumeSortingError * 100) /
                                  totalWasteVolume,
                              )
                          : 0}
                        % {t("of contaminants")}
                      </span>
                    </div>
                  )}
                  <span className="mt-2.5">
                    {numberOfAnomaly +
                      " " +
                      (numberOfAnomaly > 1
                        ? t("sorting_errors").toLowerCase()
                        : t("sorting_error").toLowerCase())}
                  </span>
                </div>
              )}
            </div>
          </div>
          {showAction && (
            <div className="w-full flex flex-wrap gap-2 mt-2">
              <div
                className="flex flex-row gap-2 items-center rounded-full hover:bg-gray-300 py-1 px-3 cursor-pointer border-solid border-1 border-[#00A7A6] text-[#00A7A6] hover:text-white hover:border-gray-300"
                onClick={() => {
                  if (!loadScreen && !loading) {
                    setDisplayComment(true);
                  }
                }}
              >
                <i class="fas fa-comment-alt"></i>
                <div className="text-sm">
                  <span>Notes ({comments.length})</span>
                </div>
              </div>
              <div
                className="flex flex-row gap-2 items-center rounded-full hover:bg-gray-300 py-1 px-3 cursor-pointer border-solid border-1 border-[#00A7A6] text-[#00A7A6] hover:text-white hover:border-gray-300"
                onClick={() => {
                  if (!loadScreen && !loading) {
                    setOpenFileModal(true);
                  }
                }}
              >
                <i className="fas fa-file"></i>
                <div className="text-sm">
                  <span>Import ({files ? files.length : 0})</span>
                </div>
              </div>
              <div
                className="flex flex-row gap-2 items-center rounded-full hover:bg-gray-300 py-1 px-3 cursor-pointer border-solid border-1 border-[#00A7A6] text-[#00A7A6] hover:text-white hover:border-gray-300"
                onClick={() => {
                  if (historyCycle && historyCycle.length > 0) {
                    toggleDisplayWeight();
                  }
                  setIsOpen(!isOpen);
                }}
              >
                <i class="fas fa-address-card"></i>
                <div className="text-sm">
                  <span>{t("Weight and producer")}</span>
                </div>
              </div>
              <div
                className="flex flex-row gap-2 items-center rounded-full hover:bg-gray-300 py-1 px-3 cursor-pointer border-solid border-1 border-[#00A7A6] text-[#00A7A6] hover:text-white hover:border-gray-300"
                onClick={() => {
                  if (historyCycle && historyCycle.length > 0) {
                    setDisplayWeight(false);
                    setDisplayClient(true);
                    setNewClient(false);
                    setDisplayStatus(true);
                    setDisplayTags(false);
                  }
                  setIsOpen(!isOpen);
                }}
              >
                <i class="fas fa-check-double"></i>
                <div className="text-sm">
                  <span>{t("Status")}</span>
                </div>
              </div>
              <div
                className="flex flex-row gap-2 items-center rounded-full hover:bg-gray-300 py-1 px-3 cursor-pointer border-solid border-1 border-[#00A7A6] text-[#00A7A6] hover:text-white hover:border-gray-300"
                onClick={() => {
                  if (historyCycle && historyCycle.length > 0) {
                    setDisplayWeight(false);
                    setDisplayClient(true);
                    setNewClient(false);
                    setDisplayStatus(false);
                    setDisplayTags(true);
                  }
                  setIsOpen(!isOpen);
                }}
              >
                <i class="fas fa-tags"></i>
                <div className="text-sm">
                  <span>{t("Tags")}</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {index === itemIndex && (
          <ActivityTimeLineTooltip
            data={timeLine}
            setItemIndex={setItemIndex}
            activeWasteCategory={activeWasteCategory}
          />
        )}

        <ContainerModalClient
          data={timeLine}
          displayClient={displayClient}
          setDisplayClient={setDisplayClient}
          weightRef={weightRef}
          newClient={newClient}
          setNewClient={setNewClient}
          handleSelectionChange={handleSelectionChange}
          setClientCode={setClientCode}
          clientName={clientName}
          setClientName={setClientName}
          setClientAddress={setClientAddress}
          weight={clientRegisteredWeight}
          setWeight={setClientRegisteredWeight}
          wasteCategoryName={wasteCategoryName}
          setWasteCategoryName={setWasteCategoryName}
          wasteCategory={wasteCategory}
          saveWeight={handleSubmit}
          totalWeight={totalWeight}
          superContainer={superContainers}
          isCharacterization={false}
          refreshAction={null}
          checkStatus={checkStatus}
          setCheckStatus={setCheckStatus}
          loading={loading}
          tags={tags}
          setTags={setTags}
          displayStatus={displayStatus}
          setDisplayStatus={setDisplayStatus}
          displayTags={displayTags}
          setDisplayTags={setDisplayTags}
          wasteName={wasteName}
        />

        <ContainerModalComment
          data={timeLine}
          displayComment={displayComment}
          setDisplayComment={setDisplayComment}
          superContainer={false}
          saveWeight={handleSubmit}
          comments={comments}
          comment={comment}
          setComment={setComment}
          loading={loading}
        />

        <ActivityCollectionFileModal
          data={timeLine}
          openFileModal={openFileModal}
          setOpenFileModal={setOpenFileModal}
          outputClient={outputClient}
          setOutputClient={setOutputClient}
          files={files}
          setFiles={setFiles}
          superContainers={superContainers}
          clientName={clientName}
          clientCode={clientCode}
          clientAddress={clientAddress}
          tags={tags}
          clientRegisteredWeight={clientRegisteredWeight}
          comments={comments}
          wasteCategoryName={wasteCategoryName}
          checkStatus={checkStatus}
        />
      </div>
    </section>
  );
};

export default ActivityCollectionRow;
