import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  formattedContainerId,
  formattedTypeContainer,
} from "../../../components/formatted/FormattedText";
import ContainerVisual from "../../../components/formatted/LoadImages";
import DeleteModal from "../../../components/modals/DeleteModal";
import MapDisplay from "../../../components/utils/Map";
import ContainerEdit from "../ContainerEdit";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const ContainerViewDetails = ({
  data,
  tableData,
  setTableData,
  countContentCategory,
}) => {
  const { t, i18n } = useTranslation();
  const token = Cookies.get("userToken");
  const API = process.env.REACT_APP_API_BACKEND;

  const materialList = JSON.parse(localStorage.getItem("materialsDescription"));
  const [unauthorisedMaterialList, setUnauthorisedMaterialList] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [formData, setFormData] = useState({});
  const [editAddress, setEditAddress] = useState(false);
  const [userList, setUserList] = useState([]);

  const handleOpenModalClick = (address) => {
    if (!data?.displayColor) {
      data.displayColor = "#fff";
    }
    setEditAddress(address);
    setFormData(data);
    setModalEdit(true);
  };

  function groupByParentCategory(data, language) {
    return data.reduce((acc, obj) => {
      const parentCategory =
        language === "en"
          ? obj.englishParentCategory
          : obj.frenchParentCategory;
      if (!acc[parentCategory]) {
        acc[parentCategory] = [];
      }
      acc[parentCategory].push(obj);
      return acc;
    }, {});
  }

  useEffect(
    () => {
      const unauthorisedMaterial = materialList.filter(
        (material) =>
          !data.contentCategoryJoin.authorizedMaterial.some(
            (item) => material.referenceName === item.value,
          ) && material.isActiveSortingError,
      );
      const groupedData = groupByParentCategory(
        unauthorisedMaterial,
        i18n.language,
      );
      setUnauthorisedMaterialList(groupedData);
    },
    // eslint-disable-next-line
    [data, i18n.language],
  );

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await axios.get(`${API}users?page=${1}&limit=${100}`, {
        headers: {
          authorization: "Bearer " + token,
        },
        params: {
          keywordFilter: "",
          sortByField: "firstName",
          orderSortByField: "1",
          listRoleFilter: {},
        },
      });

      if (response.status === 200) {
        if (response.data.data.length > 0) {
          const users = response.data.data.filter((user) => {
            if (user?.authorizedAddresses) {
              return user.authorizedAddresses.includes(data.addressId);
            }
            return false;
          });
          setUserList(users);
        }
      }
    };
    fetchUsers();
  }, [token, API, data]);

  return (
    <div className="modal-nav-item">
      <section className="modal-item-container flex flex-col">
        <div className="w-full" style={{ marginTop: -15 }}>
          <article className="modal-item-container-content w-full">
            <div className="modal-item-container-title-column mt-2">
              <h5 className="data-bold">
                {data.addressName}, {data.addressJoin.city}
              </h5>
            </div>
            <MapDisplay
              fillingRateDelivery={[]}
              markers={[data]}
              mapHeight={"50vh"}
              isActivity={true}
            />
            <div className="modal-item-container-title-column mt-2">
              <h6 className="font-semibold">
                {data.addressJoin.street}
                {data.positioning && `, ${data.positioning}`}
              </h6>
            </div>
            <h6
              className="font-medium ml-2.5 underline cursor-pointer w-40 mb-3"
              onClick={() => handleOpenModalClick(true)}
            >
              {t("Edit address")}
            </h6>
          </article>
        </div>
        <div className="w-full" style={{ marginTop: -15 }}>
          <article className="modal-item-container-content w-full">
            <div className="flex flex-row w-full">
              <div className="w-full">
                <div className="modal-item-container-title-column mt-4">
                  <h5 className="data-bold">
                    {t("Container characteristics")}
                  </h5>
                </div>
                <article className="flex flex-row">
                  <div className="flex flex-col mt-3 w-1/3">
                    <ul class="list-disc ml-5">
                      <li className="font-semibold">
                        {formattedTypeContainer(data.type)}
                      </li>
                      <li className="font-semibold mt-3">
                        Volume: {data.volume} {data.volumeUnit}
                      </li>
                      <li className="font-semibold mt-3">
                        {t("Full weight")}: {data.fullWeight} {data.weightUnit}
                      </li>
                      <li className="font-semibold mt-3">
                        {t("Length")}: {data.dimensions.length} m
                      </li>
                      <li className="font-semibold mt-3">
                        {t("Width")}: {data.dimensions.width} m
                      </li>
                      <li className="font-semibold mt-3">
                        {t("Height")}: {data.dimensions.height} m
                      </li>
                    </ul>
                    <h6
                      className="font-medium underline cursor-pointer mt-4 ml-2.5"
                      onClick={() => handleOpenModalClick(false)}
                    >
                      {t("Edit characteristics")}
                    </h6>
                  </div>
                  <div className="flex flex-row justify-items-start w-2/3">
                    <div>
                      <ContainerVisual
                        type={data.type}
                        height="300px"
                        width="300px"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </article>
        </div>
        <div className="w-full" style={{ marginTop: -15 }}>
          <article className="modal-item-container-content w-full">
            <div className="modal-item-container-title-column mt-4">
              <h5 className="data-bold">
                {t("Unauthorized materials for the category")} :{" "}
                {data.contentCategoryJoin.label}
              </h5>
            </div>
            <div className="flex flex-wrap space-x-10 p-2">
              {Object.keys(unauthorisedMaterialList).length > 0 &&
                Object.keys(unauthorisedMaterialList).map(
                  (parentCategory, index) => (
                    <div key={parentCategory} className="flex flex-col">
                      <h6 className="font-semibold" font-semibold>
                        {parentCategory}
                      </h6>
                      <ul className="list-none">
                        {unauthorisedMaterialList[parentCategory].map(
                          (material) => (
                            <li key={material._id}>
                              <div className="flex flex-row">
                                <span
                                  style={{
                                    width: 7,
                                    height: 7,
                                    backgroundColor: material.displayColor,
                                    marginTop: 7,
                                  }}
                                />
                                <span className="text-sm ml-1">
                                  {i18n.language === "en"
                                    ? material.englishFormattedName
                                    : material.frenchFormattedName}
                                </span>
                              </div>
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  ),
                )}
            </div>
            <h6 className="font-medium underline cursor-pointer mt-2 ml-2.5">
              <Link
                to={{
                  pathname: "/waste",
                  search: `?wasteId=${data.contentCategory}&openEdit=true&sortingErrors=true`,
                }}
                target="_blank"
                className="visited:text-black"
              >
                <div>{t("Edit the list of unauthorized materials")}</div>
              </Link>
            </h6>
            {countContentCategory > 1 && (
              <div className="flex flex-row ml-2.5 w-auto">
                <i class="fas fa-exclamation-triangle mr-2 text-danger"></i>
                <h6 className="font-medium">
                  Le flux {data.contentCategoryJoin.label} est attribué à{" "}
                  {countContentCategory - 1} autres contenants
                </h6>
              </div>
            )}
          </article>
        </div>
        <div className="w-full" style={{ marginTop: -15 }}>
          <article className="modal-item-container-content w-full">
            <div className="modal-item-container-title-column mt-4">
              <h5 className="data-bold">{t("Managers of this container")}</h5>
              <div className="flex flex-wrap space-x-5">
                {userList.length > 0 &&
                  userList.map((user) => (
                    <div className="flex flex-col items-center w-20 h-20 border border-gray-600 rounded-md px-2">
                      <i className="fas fa-user text-3xl mt-1"></i>
                      <span className="text-xs mt-1 truncate w-full text-center">
                        {user.firstName}
                      </span>
                      <span className="text-xs truncate w-full text-center">
                        {user.lastName}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </article>
        </div>
        <div className="w-full">
          <div className="modal-item-container-title-column mt-2">
            <h5 className="data-bold">{t("Delete this container")}</h5>
          </div>
          <div className="flex items-center ml-4">
            <span className="flex-grow">
              * {t("Delete all data and history of this container")}.{" "}
              {t("Please note, this action is irreversible")}.
            </span>
            <button
              className="button-danger rounded"
              onClick={() => setModalDelete(true)}
              style={{ marginTop: -15 }}
            >
              {t("Delete")}
            </button>
          </div>
        </div>
        {modalEdit && formData._id === data._measurement && (
          <ContainerEdit
            tableData={tableData}
            setTableData={setTableData}
            editFormData={formData}
            setEditFormData={setFormData}
            modalEdit={true}
            setModalEdit={setModalEdit}
            editAddress={editAddress}
          />
        )}
        {modalDelete && (
          <DeleteModal
            modal={modalDelete}
            setModal={setModalDelete}
            id={data._id}
            message1={
              `${t("Are you sure you want to delete this container")} ` +
              formattedContainerId(data.containerId) +
              data.containerId +
              " ?"
            }
            message2={formattedContainerId(data.containerId) + data.containerId}
            messageValidation={t("The container has been successfully deleted")}
            path={"container/delete/"}
            tableData={tableData}
            setTableData={setTableData}
          />
        )}
      </section>
    </div>
  );
};

export default ContainerViewDetails;
