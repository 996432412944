import React from "react";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import "../styles/Font";
import CoverFillingCyclePDF from "./CoverFillingCyclePDF";
import { useTranslation } from "react-i18next";
import { ImageComponent } from "../wasteQuality/ImageComponent";

export const FillingCyclePdfDocument = ({
  data,
  timeLine,
  dropOff,
  corporationName,
  collectionFillingLevel,
  numberOfAnomaly,
}) => {
  const { t, i18n } = useTranslation();
  let clientLogo = null;
  try {
    clientLogo = require(`../../../static/assets/${timeLine.corporation}.png`);
  } catch (error) {
    console.warn("Logo not found, using default logo.");
  }
  const groupedData = [];
  for (let i = 0; i < data.length; i += 2) {
    groupedData.push(data.slice(i, i + 2));
  }

  return (
    <Document
      creator="Akanthas"
      producer="Akanthas"
      style={{ fontFamily: "Open Sans" }}
    >
      <CoverFillingCyclePDF
        data={data}
        timeLine={timeLine}
        dropOff={dropOff}
        language={i18n.language}
        clientLogo={clientLogo}
        corporationName={corporationName}
        collectionFillingLevel={collectionFillingLevel}
        numberOfAnomaly={numberOfAnomaly}
      />

      {groupedData
        .flat()
        .reduce((acc, item, index) => {
          if (index % 6 === 0) acc.push([]);
          acc[acc.length - 1].push(item);
          return acc;
        }, [])
        .map((group, pageIndex) => (
          <Page key={pageIndex} size="A4">
            {/* Bande bleue en haut */}
            <View
              fixed
              style={{ height: 20, backgroundColor: "#0F265C", width: "100%" }}
            />
            <Image
              source={require("../../../static/assets/Akanthas-logo-RVB.png")}
              style={{ width: 111, height: 40, marginLeft: 10 }}
            />

            {/* Contenu avec padding */}
            <View style={{ padding: 20 }}>
              {Array.from({ length: 3 }).map((_, rowIndex) => (
                <View
                  key={rowIndex}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 20,
                  }}
                >
                  {/* Deux images côte à côte */}
                  {group
                    .slice(rowIndex * 2, rowIndex * 2 + 2)
                    .map((item, subIndex) => (
                      <View
                        key={subIndex}
                        style={{
                          width: "48%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ImageComponent
                          data={item}
                          drawMaterial={false}
                          height={190}
                        />
                        <Text
                          style={{
                            fontSize: 10,
                            marginTop: 10,
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {`${t("Level")} ${item.filling_level}%`}
                        </Text>
                      </View>
                    ))}
                </View>
              ))}
            </View>
          </Page>
        ))}
    </Document>
  );
};
