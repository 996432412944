import React from "react";
import { Page, View, Text, Image, StyleSheet, Font } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import cover from "../../../static/assets/canva-report.jpg";
import MulishLight from "../../../static/fonts/Mulish/Mulish-Light.ttf";
import MulishMedium from "../../../static/fonts/Mulish/Mulish-Medium.ttf";
import MulishBold from "../../../static/fonts/Mulish/Mulish-Bold.ttf";
import MulishBlack from "../../../static/fonts/Mulish/Mulish-Black.ttf";
import {
  formattedDate,
  formattedTime,
} from "../../formatted/FormattedDateAndTime";
import { capitalizeFirstLetter } from "../../utils/Date";
import {
  formattedTypeContainer,
  startsWithVowel,
} from "../../formatted/FormattedText";

Font.register({
  family: "Mulish",
  fonts: [
    { src: MulishLight, fontWeight: 200 },
    { src: MulishMedium, fontWeight: 500 },
    { src: MulishBold, fontWeight: 700 },
    { src: MulishBlack, fontWeight: 900 },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: 0,
    fontFamily: "Mulish",
  },
  container: {
    flex: 1,
    position: "relative",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  content: {
    flex: 1,
    flexDirection: "row",
    position: "relative",
  },
  leftSection: {
    width: "30%",
    color: "#fff",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  rightSection: {
    width: "70%",
    marginTop: "40%",
    marginRight: "15%",
    padding: 20,
    borderRadius: 10,
  },
  title: {
    fontSize: 30,
    fontWeight: "bold",
    color: "#000",
    textAlign: "right",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#000",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "right",
  },
  text: {
    fontSize: 11,
    color: "#5B5555",
    textAlign: "right",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  logoContainer: {
    alignSelf: "flex-end",
    marginTop: 50,
    height: "auto",
  },
  logo: {
    maxWidth: "80px",
    height: "auto",
  },
});

const CoverFillingCyclePDF = ({
  timeLine,
  dropOff,
  language,
  clientLogo,
  corporationName,
  collectionFillingLevel,
  numberOfAnomaly,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.container}>
        <Image src={cover} style={styles.backgroundImage} />
        <View style={styles.content}>
          <View style={styles.leftSection}>
            <View style={{ marginLeft: 50, marginBottom: 60 }}>
              <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                {t("prepared for").toUpperCase()} :
              </Text>
              <Text style={{ fontSize: 10, fontWeight: "bold" }}>
                {corporationName}
              </Text>
            </View>
          </View>
          <View style={styles.rightSection}>
            <Text style={[styles.subtitle, { fontSize: 12 }]}>
              {t("Drop-off")} : {formattedDate(dropOff, language, true)},{" "}
              {formattedTime(dropOff)}
            </Text>
            <Text style={[styles.subtitle, { fontSize: 12 }]}>
              {t("Pick-up")} : {formattedDate(timeLine._time, language, true)},{" "}
              {formattedTime(timeLine._time)}
            </Text>
            {i18n.language === "en" ? (
              <>
                <Text style={[styles.title, { marginTop: 20 }]}>FILL</Text>
                <Text style={styles.title}>ANALYSIS</Text>
              </>
            ) : i18n.language === "fr" ? (
              <>
                <Text style={[styles.title, { marginTop: 20 }]}>ANALYSE</Text>
                <Text style={styles.title}>DE REMPLISSAGE</Text>
              </>
            ) : (
              <>
                <Text style={[styles.title, { marginTop: 20 }]}>ANÁLISIS</Text>
                <Text style={styles.title}>DE LLENADO</Text>
              </>
            )}
            <Text
              style={[
                styles.subtitle,
                {
                  marginTop: 170,
                  fontSize: 15,
                  marginBottom: 30,
                  textTransform: "capitalize",
                },
              ]}
            >
              {timeLine.wasteName}
            </Text>
            <Text style={styles.text}>
              {`${capitalizeFirstLetter(t("fill cycle analysis"))} ${language === "en" ? "of the" : language === "fr" ? "de" : "del"}`}
            </Text>
            <Text style={styles.text}>
              {`${language === "fr" ? (startsWithVowel(formattedTypeContainer(timeLine.type)) ? "l'" : "la ") : ""}${formattedTypeContainer(timeLine.type)} ${timeLine.wasteName} ${t("of the")} ${timeLine.addressName},`}
            </Text>
            <Text style={styles.text}>
              {`${t("from the deposit on")} ${formattedDate(
                dropOff,
                language,
                true,
              )} ${language === "en" ? "at" : language === "fr" ? "à" : "al"} ${formattedTime(dropOff)}`}
            </Text>
            <Text style={styles.text}>
              {`${t("to the pick-up on")} ${formattedDate(
                timeLine._time,
                language,
                true,
              )} ${language === "en" ? "at" : language === "fr" ? "à" : "al"} ${formattedTime(timeLine._time)}.`}
            </Text>
            <Text style={styles.text}>
              {`${capitalizeFirstLetter(t("results"))} : ${collectionFillingLevel}% ${t("filling and")} ${numberOfAnomaly}`}
            </Text>
            <Text style={styles.text}>{t("identified sorting errors")}.</Text>
            {clientLogo && (
              <View style={styles.logoContainer}>
                <Image src={clientLogo} style={styles.logo} />
              </View>
            )}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default CoverFillingCyclePDF;
