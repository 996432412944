import {
  handleEditFormCancel,
  handleEditFormSubmit,
} from "../../components/modals/EditableTable";
import { Modal, ModalBody, Form } from "reactstrap";
import { useState } from "react";
import { WasteGeneralInformations } from "./WasteFormTab/WasteGeneralInformations";
import { WasteCosts } from "./WasteFormTab/WasteCosts";
import { WasteSortingErrors } from "./WasteFormTab/WasteSortingErrors";
import { toast } from "react-toastify";

const WasteEdit = ({
  tableData,
  setTableData,
  editFormData,
  setEditFormData,
  unitList,
  modalEdit,
  setModalEdit,
  sortingErrors,
}) => {
  const [showGeneralInformations, setShowGeneralInformations] = useState(
    sortingErrors ? false : true,
  );
  const [showCosts, setShowCosts] = useState(false);
  const [showSortingErrors, setShowSortingErrors] = useState(
    sortingErrors ? true : false,
  );
  const [formIsVerified, setFormIsVerified] = useState(true);
  const [errorForm, setErrorForm] = useState("");
  const [sendingForm, setSendingForm] = useState(false);
  const [items, setItems] = useState(
    localStorage.getItem("materialsDescription") &&
      JSON.parse(localStorage.getItem("materialsDescription"))
        .map((v) =>
          editFormData.authorizedMaterial
            .map((v) => v.value)
            .includes(v.referenceName)
            ? { ...v, isChecked: false }
            : { ...v, isChecked: true },
        )
        .filter((item) => item.isActiveSortingError),
  );

  const toggle = () => setModalEdit(false);

  const path = "wastecategory";
  return (
    <Modal isOpen={modalEdit} toggle={toggle}>
      <Form
        onSubmit={async (event) => {
          event.preventDefault();

          setSendingForm(true);
          const response = await handleEditFormSubmit(editFormData, path);

          if (response.status === 200) {
            const newData = [...tableData];
            const index = tableData.findIndex(
              (elem) => elem._id === editFormData._id,
            );
            newData[index] = editFormData;
            setTableData(newData);
            setModalEdit(false);
            toast.success("La matière a bien été modifiée", {
              className: "custom-toast",
            });
          } else {
            setErrorForm(response.data.error);
          }
        }}
      >
        <section className="flex flex-col p-3">
          {/* Header */}
          <section className="flex justify-between max-md:flex-col mb-4">
            <div className="flex">
              <h4 className="font-semibold text-2xl">Modifier flux</h4>
              {editFormData.label && (
                <h4 className="ml-1 text-red font-semibold text-2xl">
                  | {editFormData.label}
                </h4>
              )}
            </div>

            <div className="flex text-base max-md:justify-end">
              <button
                className="px-3 py-2 mr-2 border border-gray-300 rounded-md hover:bg-neutral-100"
                onClick={() => {
                  handleEditFormCancel(setModalEdit);
                }}
              >
                Annuler
              </button>
              <button
                className={`px-3 py-2 rounded text-white ${!sendingForm && formIsVerified ? "bg-cyan" : "bg-neutral-400 hover:bg-neutral-400"}`}
                type="submit"
                disabled={!formIsVerified || sendingForm}
              >
                Sauvegarder
              </button>
            </div>
          </section>

          {/* Tabs */}
          <section className="flex max-md:flex-col text-base text-cyan ">
            <div
              className={`mr-2 px-1 cursor-pointer ${showGeneralInformations ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(true);
                setShowCosts(false);
                setShowSortingErrors(false);
              }}
            >
              Infos générales
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showCosts ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowCosts(true);
                setShowSortingErrors(false);
              }}
            >
              Coûts
            </div>
            <div
              className={`mr-2 px-1 cursor-pointer ${showSortingErrors ? "font-semibold border-solid border-b-2 border-cyan" : null}`}
              onClick={() => {
                setShowGeneralInformations(false);
                setShowCosts(false);
                setShowSortingErrors(true);
              }}
            >
              Flux non autorisés {`(erreurs de tri)`}
            </div>
          </section>

          {/* Error */}
          {errorForm && (
            <p className="mx-auto my-0 px-2 py-1 bg-yellow text-white rounded">
              {errorForm}
            </p>
          )}
        </section>

        <ModalBody>
          {showGeneralInformations && (
            <WasteGeneralInformations
              formData={editFormData}
              setFormData={setEditFormData}
              setErrorForm={setErrorForm}
              setFormIsVerified={setFormIsVerified}
            />
          )}

          {showCosts && (
            <WasteCosts
              formData={editFormData}
              setFormData={setEditFormData}
              unitList={unitList}
            />
          )}

          {showSortingErrors && items && (
            <WasteSortingErrors
              formData={editFormData}
              setFormData={setEditFormData}
              items={items}
              setItems={setItems}
            />
          )}
        </ModalBody>
      </Form>
    </Modal>
  );
};

export default WasteEdit;
